<template>
  <div class="promo-video">

    <div class="promo-video__room">

      <div class="promo-video__room__floor">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[51]}/floor.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[7]}/floor.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[11]}/floor.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[6]}/floor.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[45]}/floor.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[5]}/floor.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[3]}/floor.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[14]}/floor.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[0]}/floor.png`">
      </div>

      <div class="promo-video__room__center_wall">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[51]}/center_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[7]}/center_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[11]}/center_wall.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[6]}/center_wall.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[45]}/center_wall.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[5]}/center_wall.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[3]}/center_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[14]}/center_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[0]}/center_wall.png`">
      </div>

      <div class="promo-video__room__right_wall">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[51]}/right_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[7]}/right_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[11]}/right_wall.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[6]}/right_wall.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[45]}/right_wall.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[5]}/right_wall.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[3]}/right_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[14]}/right_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[0]}/right_wall.png`">
      </div>

      <div class="promo-video__room__left_wall">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[51]}/left_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[7]}/left_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[11]}/left_wall.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[6]}/left_wall.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[45]}/left_wall.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[5]}/left_wall.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[3]}/left_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[14]}/left_wall.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[0]}/left_wall.png`">
      </div>

      <div class="promo-video__room__window">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[51]}/window.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[7]}/window.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[11]}/window.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[6]}/window.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[45]}/window.png`">
        <!-- <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[5]}/window.png`"> -->
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[3]}/window.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[14]}/window.png`">
        <img :src="`/promo-video/linked_prepared_rooms/${ALL_ROOMS[0]}/window.png`">
      </div>
    </div>

    <div class="promo-video__paints">
      <img src="https://lh3.googleusercontent.com/NXjHu9hp3hO5DVS9QoiwOM-dx9Hgrwi8ICOsTr-ECuek9XkInePVsegHb-LHKUS6eApuMmeeFNb-mAoWYJv67MOL-RceTdQRl5K4dTI=w600">
      <img src="/promo-video/00-myape2.png">
      <img src="https://lh3.googleusercontent.com/w3nBLj5A7rHidrAsx391FNTJ8btEqQJw31eCnl4wmamLWFQmgjCOl0y5gReVSQccsLKxwDXwX77F10P2oW7kqkYIKbkRv-RvWGov=s0">
      <img src="https://lh3.googleusercontent.com/-EMuafC3wRyIi0FcAkhnnWU4ho9FGYGIpFpzKayl-ZFa_xhKj3OYdy_TD2K7OrKcQpD85AQhi78wWUWJp_eqVVhUlTowGRJoAH0KzA=w600">
      <img src="/promo-video/00-myape1.png">

      <img src="https://lh3.googleusercontent.com/NXjHu9hp3hO5DVS9QoiwOM-dx9Hgrwi8ICOsTr-ECuek9XkInePVsegHb-LHKUS6eApuMmeeFNb-mAoWYJv67MOL-RceTdQRl5K4dTI=w600">
    </div>

  </div>
</template>

<script>

// import Vue from 'vue';
// import { Input, Button, Card, Loading, Select, Option } from 'element-ui';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'PromoVideo',
  components: {
  },
  data() {
    return {
      ALL_ROOMS: [
        'abstraction: der blaue reiter |olga',
        'abstraction: malevich |tanka',
        'abstraction: suprematism |cypubog',
        'alps |rustamha',
        'art museum |cypubog',
        'basketball: city |cypubog',
        'basketball: jungle |cypubog',
        'bible black |kapuya',
        'blue room |cypubog',
        'castle |kapuya',
        'city 17 |kapuya',
        'collector |kapuya',
        'cretaceous |olga',
        'cyberpunk: 2049 |tanka',
        'cyberpunk: cerasus city 02 |kapuya',
        'cyberpunk: fhloston |cypubog',
        'cyberpunk: orion |olga',
        'cyberpunk: starship |kapuya',
        'cyberpunk: wallace corporation |kapuya',
        'decalcomania |olga',
        'disco |tanka',
        'gradient 1 |kapuya',
        'gradient 2 |kapuya',
        'gradient 3 |kapuya',
        'gradient 4 |kapuya',
        'gradient 5 |kapuya',
        'gradient 6 |kapuya',
        'gradient 7 |kapuya',
        'gradient 8 |kapuya',
        'gravitation |kapuya',
        'lodge |olga',
        'lombard street |cypubog',
        'maya |olga',
        'moon |rustamha',
        'post-impressionism |cypubog',
        'retrowave |tanka',
        'ruins |olga',
        'sakura |kapuya',
        'simple 1 |olga',
        'simple 2 |olga',
        'solarpunk: mars |cypubog',
        'solarpunk: pool |cypubog',
        'steampunk: dunwall |cypubog',
        'steampunk: lamps |tanka',
        'steampunk: pipes |cypubog',
        'steampunk: robot |kapuya',
        'the wave |olga',
        'wall street |cypubog',
        'war |cypubog',
        'yacht club: dawn |cypubog',
        'yacht club: night |cypubog',
        'yacht club: sleep |kapuya',
      ], // ALL_ROOMS

      timeline: anime.timeline(),

      PAINT_MOVING_TIME: 500,
      OPACITY_HIDING: { value: 0, duration: 200, easing: 'linear' },
      OPACITY_SHOWING: { value: 1, duration: 200, easing: 'linear' },

      toggledWalls: {
        floor: 1,
        center_wall: 1,
        right_wall: 1,
        left_wall: 1,
        window: 1,
      },

      allWallTypes: ['floor', 'center_wall', 'right_wall', 'left_wall', 'window'],

      currentLeftPosition: 321,
    };
  }, // data
  mounted() {
    setTimeout(() => {
      console.log('START')
      console.log('START')
      console.log('START')
      console.log('START')
      console.log('START')
      console.log('START')
      setTimeout(() => {
        this.startAnimation();
      }, 1000);
    }, 5000);
  },
  watch: {
  },
  methods: {

    // wallType = 'floor', 'center_wall', 'right_wall', 'left_wall', 'window'
    toggleWall(wallType, isBiggerDelay, isReset) {
      const offset = isBiggerDelay === true ? 1000 : 0;
      let hidingWallIndex = this.toggledWalls[wallType];
      let showingWallIndex = this.toggledWalls[wallType] + 1;

      if (isReset) {
        showingWallIndex = 1;
        this.toggledWalls[wallType] = 0;
      }

      this.timeline
        .add({
          delay: 100,
          targets: `.promo-video__room__${wallType} img:nth-child(${hidingWallIndex})`,
          opacity: this.OPACITY_HIDING,
        }, `+=${offset}`)
        .add({
          delay: 0,
          targets: `.promo-video__room__${wallType} img:nth-child(${showingWallIndex})`,
          opacity: this.OPACITY_SHOWING,
        }, '-=50');
        // }, '-=100');

      this.toggledWalls[wallType] += 1;

      // удалить стену, если уже обновили ее максимум раз
      const maxWallChanges = document.querySelectorAll(".promo-video__room__floor > img").length;
      if (this.toggledWalls[wallType] == maxWallChanges) {
        this.allWallTypes.splice(this.allWallTypes.indexOf(wallType), 1);
      }
    },

    getRandomInt(min, max) {
      // Максимум и минимум включаются, т.е. getRandomInt(0, 2) может вернуть 0, 1 и 2
      // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Math/random#%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5_%D1%81%D0%BB%D1%83%D1%87%D0%B0%D0%B9%D0%BD%D0%BE%D0%B3%D0%BE_%D1%86%D0%B5%D0%BB%D0%BE%D0%B3%D0%BE_%D1%87%D0%B8%D1%81%D0%BB%D0%B0_%D0%B2_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%BD%D0%BE%D0%BC_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B2%D0%B0%D0%BB%D0%B5_%D0%B2%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE

      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    randomizeWalls() {
      for (let i = 0; i < 40; i++) {
        if (this.allWallTypes.length > 0) {
          let wallType = this.allWallTypes[this.getRandomInt(0, this.allWallTypes.length - 1)];
          const isBiggerDelay = i === 0;
          this.toggleWall(wallType, isBiggerDelay);
        }
        // else {
        //   console.log('finish', i)
        // }
      }

      // allWallTypes: ['floor', 'center_wall', 'right_wall', 'left_wall', 'window'],

      this.toggleWall('floor', false, true);
      this.toggleWall('center_wall', false, true);
      this.toggleWall('right_wall', false, true);
      this.toggleWall('left_wall', false, true);
      this.toggleWall('window', false, true);

    },

    movePaints(disableDelay) {
      // const PAINTS_LEFT_POSTIONS = [-187, -695, -1203, -1711];
      // for (const value of PAINTS_LEFT_POSTIONS) {

      const DISTANCE_BETWEEN_POSITIONS = 508;
      this.currentLeftPosition = this.currentLeftPosition - DISTANCE_BETWEEN_POSITIONS;

      this.timeline
        .add({
          delay: disableDelay ? 0 : 500,
          targets: '.promo-video__paints',
          left: {
            // value: value,
            value: this.currentLeftPosition,
            duration: this.PAINT_MOVING_TIME,
            easing: 'easeInCubic',
          },
        });
    },

    startAnimation() {

      for (let index = 0; index < 4; index++) {
        this.movePaints();
      }

      this.randomizeWalls();

      this.movePaints(true);

    }, // startAnimation

  }, // methods
  computed: {
  }, // computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .promo-video {
    overflow: hidden;

    height: 662px;
    width: 800px;
    margin: 30px auto 300px;
    // border: 1px solid #000;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .promo-video__paints {
    justify-content: space-between;
    position: absolute;
    display: flex;
    top: 121px;
    left: 321px;
    width: 2700px;

    img {
      width: 160px;
      height: 225px;
    }
  }

  .promo-video__room {
    // position: relative;
    // width: 100%;
    // height: 100%;

    img {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;

      &:nth-child(1) {
        opacity: 1;
      }
    }
  }
</style>
